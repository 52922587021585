<template>
  <div class="file_upload">
    <div class="file_upload_content">
      <el-form class="toolbar" :rules="rules" ref="formRef" :model="uploadForm">
        <el-form-item label="文件类型" prop="channel">
          <el-select clearable size="mini" v-model="uploadForm.channel">
            <el-option
              v-for="item in channelList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="文件" prop="file">
          <el-upload
            class="upload-demo"
            :auto-upload="false"
            drag
            :file-list="fileList"
            ref="upload"
            :limit="1"
            :data="{
              channel: uploadForm.channel,
              fileName: uploadForm.fileName,
              ticket: uploadForm.ticket,
            }"
            :on-success="handleSuccess"
            :on-change="handleChange"
            :on-remove="handleRemove"
            action="/manage/file/upload"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button
            @click="upload"
            :loading="submitLoading"
            type="primary"
            size="mini"
            >上传</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import api from "@/api/orderManage/fileUpload.js";
import DataUtil from "@/utils/config/dataUtil";
export default {
  data() {
    return {
      uploadForm: {
        channel: null,
        file: null,
        fileName: null,
        ticket: DataUtil.ticket(),
      },
      fileList: [],
      submitLoading: false,
      channelList: [],
      rules: {
        file: [{ required: true, message: "请上传文件", trigger: "change" }],
        channel: [
          { required: true, message: "请选择文件类型", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    upload() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          console.log("点击上传");
          this.submitLoading = true;
          this.$refs.upload.submit();
        }
      });
    },
    handleSuccess(response) {
      console.log(response);
      this.submitLoading = false;
      if (response.code == "0000") {
        this.$message.success(response.message);
        this.$refs.formRef.resetFields();
        this.fileList = [];
      }
    },
    handleRemove(file) {
      this.uploadForm.file = null;
      this.uploadForm.fileName = null;
    },
    handleChange(file) {
      this.uploadForm.file = file;
      this.uploadForm.fileName = file.name;
    },
    getUploadFileChannel() {
      api.getUploadFileChannelApi({}).then((res) => {
        if (res.code == "0000") {
          this.channelList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  mounted() {
    this.getUploadFileChannel();
  },
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.file_upload {
  width: 100%;
  min-height: 600px;
  box-sizing: border-box;
  padding: 0 100px;
  .file_upload_content {
    width: 30%;
  }
}
</style>
