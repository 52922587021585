import request from "@/utils/http/requset";

function getUploadFileChannelApi(data) {
    return request({
        url: "/manage/file/upload/channel",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}
export default {
    getUploadFileChannelApi,
};
